const columns = Object.freeze([
  {
    key: 'full_name',
    label: 'ФИО получателя',
    sortable: true,
    thClass: 'w-33 text-center align-middle',
    tdClass: 'text-center align-middle',
  },
  {
    key: 'phone',
    label: 'Номер телефона',
    thClass: 'w-33 text-center align-middle',
    tdClass: 'text-center align-middle',
  },
  {
    key: 'orders_count',
    label: 'Кол-во полученных заказов',
    thClass: 'w-33 text-center align-middle',
    tdClass: 'text-center align-middle',
    sortable: true,
  },
  {
    key: 'actions',
    label: '',
    thClass: 'text-center align-middle',
    tdClass: 'text-center align-middle',
  },
])

const modal = {
  delete: {
    title: 'Удаление получателя?',
    content: 'Вы дейсвительно хотите удалить получателя',
    visible: false,
  },
}

export { columns, modal }
