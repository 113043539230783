var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[(_vm.maxDate && _vm.minDate)?[_c('table-header',_vm._b({on:{"update":_vm.onUpdateFilter,"reset":_vm.onResetFilter}},'table-header',{
      minDate: _vm.minDate,
      maxDate: _vm.maxDate,
      currentPage: _vm.page,
    },false))]:_vm._e(),_c('table-body',_vm._b({on:{"update:page-size":_vm.onPerPageChange,"update:page":_vm.onPageChange}},'table-body',{
      currentPage: _vm.page,
      pageCount: _vm.pageCount,
      itemCount: _vm.itemCount,
      pageSize: _vm.page_size
    },false),[_c('b-table',_vm._b({staticClass:"position-relative",on:{"sort-changed":_vm.onSort},scopedSlots:_vm._u([{key:"cell(phone)",fn:function(ref){
    var item = ref.item;
return [_c('a',{attrs:{"href":("tel:+" + (item.phone))}},[_vm._v(_vm._s(_vm._f("VMask")(item.phone,'+#(###) ###-##-##')))])]}},{key:"cell(actions)",fn:function(ref){
    var item = ref.item;
return [_c('table-buttons',_vm._b({on:{"edit":function($event){return _vm.onEdit(Object.assign({}, item))},"delete":function($event){return _vm.onDelete(Object.assign({}, item))}}},'table-buttons',{
            disabled: _vm.hasPermission,
          },false))]}},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('span',[_vm._v("Загрузка...")])],1)]},proxy:true}])},'b-table',{
        items: _vm.recipients,
        fields: _vm.fields,
        striped: _vm.striped,
        hover: _vm.hover,
        responsive: _vm.responsive,
        showEmpty: _vm.recipients && !_vm.recipients.length && !_vm.busy,
        emptyText: _vm.emptyText,
        perPage: _vm.page_size,
        busy: _vm.loading,
      },false))],1),_c('common-dialog',_vm._b({on:{"confirm":_vm.onDeleteItem}},'common-dialog',{
      title: _vm.modal.delete.visible ? _vm.modal.delete.title : '',
      value: _vm.modal.delete.visible
    },false),[_c('p',[_vm._v(" "+_vm._s(((_vm.modal.delete.content) + " " + _vm.full_name + "?"))+" ")])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }